import React from "react"

import styles from "./slide-right.module.scss"

import { classnames } from "../../../../../utils/helpers"

import { useStaticQuery, graphql } from "gatsby"
import Image from "../../../../../components/image/image"

export default function SlideRight({ animationDuration, reduceMotion }) {
  const images = useStaticQuery(graphql`
    query {
      Brick: file(relativePath: { eq: "home/header/slide-one/brick-row.png" }) {
        childImageSharp {
          fixed(width: 1248) {
            ...fluidImageWithDefault
          }
        }
      }
      Luigi: file(relativePath: { eq: "home/header/slide-one/luigi.png" }) {
        childImageSharp {
          fixed(width: 550) {
            ...fluidImageWithDefault
          }
        }
      }
      Mario: file(relativePath: { eq: "home/header/slide-one/mario.png" }) {
        childImageSharp {
          fixed(width: 980) {
            ...fluidImageWithDefault
          }
        }
      }
    }
  `)

  return (
    <div
      style={{
        "--animation-duration": `${animationDuration}ms`,
      }}
      className={classnames([
        reduceMotion && styles["characters--reduced-motion"],
      ])}
    >
      <Image
        {...images.Brick.childImageSharp.fixed}
        className={`image-group__item ${styles.BrickRow}`}
      />
      <Image
        {...images.Luigi.childImageSharp.fixed}
        className={`image-group__item ${styles.Luigi}`}
      />
      <Image
        {...images.Mario.childImageSharp.fixed}
        className={`image-group__item ${styles.Mario}`}
      />
    </div>
  )
}
