import React from "react"

import styles from "./slide-left.module.scss"

import { classnames } from "../../../../../utils/helpers"

import { useStaticQuery, graphql } from "gatsby"
import Image from "../../../../../components/image/image"

export default function SlideLeft({ animationDuration, reduceMotion }) {
  const images = useStaticQuery(graphql`
    query {
      Coin: file(relativePath: { eq: "home/header/coin.png" }) {
        childImageSharp {
          fixed(width: 242) {
            ...fluidImageWithDefault
          }
        }
      }
    }
  `)

  return (
    <div
      style={{
        "--animation-duration": `${animationDuration}ms`,
      }}
      className={classnames([reduceMotion && styles["coins--reduced-motion"]])}
    >
      <Image
        {...images.Coin.childImageSharp.fixed}
        className={`image-group__item ${styles.Coin} ${styles.CoinOne}`}
      />
      <Image
        {...images.Coin.childImageSharp.fixed}
        className={`image-group__item ${styles.Coin} ${styles.CoinTwo}`}
      />
      <Image
        {...images.Coin.childImageSharp.fixed}
        className={`image-group__item ${styles.Coin} ${styles.CoinThree}`}
      />
      <Image
        {...images.Coin.childImageSharp.fixed}
        className={`image-group__item ${styles.Coin} ${styles.CoinFour}`}
      />
      <Image
        {...images.Coin.childImageSharp.fixed}
        className={`image-group__item ${styles.Coin} ${styles.CoinFive}`}
      />
      <Image
        {...images.Coin.childImageSharp.fixed}
        className={`image-group__item ${styles.Coin} ${styles.CoinSix}`}
      />
    </div>
  )
}
