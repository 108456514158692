import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"

import useEventListener from "../../hooks/use-event-listener"
import useBreakPoints from "../../hooks/use-breakpoints"

import { classnames } from "../../utils/helpers"

import styles from "./blade-decoration.module.scss"

const COLORS = {
  red: "#e70112",
  blue: "#0058BC",
  yellow: "#FFD100",
}

const draw = (
  canvas,
  type,
  angled,
  backgroundColor,
  height,
  baseWidth,
  breakpoint
) => {
  const ratio = height / baseWidth
  const ctx = canvas.getContext("2d")
  let settings = {}

  switch (type) {
    case "small-wave":
      settings.amplitude = settings.frequency = 6
      break
    case "triangle":
      settings.amplitude = 14
      break
    case "straight":
      settings.amplitude = 0
      break
    default:
      settings.amplitude = 9
      settings.frequency = 18
  }

  // specific settings for each breakpoint
  if (type === "wave" && !angled) {
    settings.amplitude = breakpoint > 2 ? 9 : breakpoint > 1 ? 7 : 4.5
    settings.frequency = breakpoint > 2 ? 18 : breakpoint > 1 ? 14 : 10
  }

  canvas.width = window.innerWidth
  canvas.height = Math.min(window.innerWidth * ratio, height)

  const maxHeight = ctx.canvas.height - settings.amplitude
  const width = ctx.canvas.width

  ctx.clearRect(0, 0, canvas.width, canvas.height)

  ctx.beginPath()
  ctx.moveTo(0, 0)
  ctx.lineTo(0, maxHeight)

  if (type === "straight") {
    // ctx.lineTo(width, 0)
  } else {
    const r = Math.atan2(
      ctx.canvas.height - settings.amplitude * 2,
      ctx.canvas.width
    )

    const mat = angled
      ? [Math.cos(r), -Math.sin(r), Math.sin(r), Math.cos(r)]
      : [1, 0, 0, 1] // the rotation matrix

    for (let x = 0; x < width + 100; x = x + 1) {
      const y =
        type === "triangle"
          ? settings.amplitude -
            Math.abs((x % (settings.amplitude * 2)) - settings.amplitude)
          : settings.amplitude * Math.sin(x / settings.frequency)

      const p = [x - 100, maxHeight + y]
      const tp = [mat[0] * p[0] + mat[2] * p[1], mat[1] * p[0] + mat[3] * p[1]]

      ctx.lineTo(tp[0], tp[1])
    }
  }

  ctx.lineTo(width, 0)

  ctx.fillStyle = COLORS[backgroundColor]
  ctx.fill()
}

// TODO: draw shapes and maybe patterns
export default function BladeDecoration({
  height,
  type,
  angled,
  flip,
  backgroundColor,
  className,
}) {
  const currentBP = useBreakPoints()
  const canvasRef = useRef()
  const drawTimer = useRef()
  const baseWidth = angled || currentBP > 2 ? 1440 : currentBP > 1 ? 640 : 320

  useEventListener("resize", () => {
    clearTimeout(drawTimer.current)

    drawTimer.current = setTimeout(() => {
      draw(
        canvasRef.current,
        type,
        angled,
        backgroundColor,
        height,
        baseWidth,
        currentBP
      )
    }, 300)
  })

  useEffect(() => {
    const timer = drawTimer.current

    draw(
      canvasRef.current,
      type,
      angled,
      backgroundColor,
      height,
      baseWidth,
      currentBP
    )

    return () => {
      clearTimeout(timer)
    }
  }, [baseWidth, angled, backgroundColor, currentBP, height, type])

  return (
    <div
      className={classnames([
        styles.BladeDecoration,
        flip ? styles["BladeDecoration--flip"] : "",
        className,
      ])}
    >
      <canvas
        width={baseWidth}
        height={height}
        style={{ maxHeight: `${height}px` }}
        ref={canvasRef}
        aria-hidden="true"
      />
    </div>
  )
}

BladeDecoration.defaultProps = {
  height: 262,
  type: "wave",
  angled: true,
  flip: false,
  backgroundColor: "red",
}

BladeDecoration.propTypes = {
  height: PropTypes.number,
  type: PropTypes.oneOf(["wave", "small-wave", "triangle", "straight"]),
  angled: PropTypes.bool,
  flip: PropTypes.bool,
  backgroundColor: PropTypes.oneOf(["red", "blue", "yellow"]),
  className: PropTypes.string,
}
