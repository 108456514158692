import { useContext, useState, useEffect, useMemo } from "react"

import NcloodContext from "../contexts/nclood-context"

import genericMii from "../images/global/mii-generic.png"

export default function useNclood() {
  const { loaded, error, user } = useContext(NcloodContext)
  const [signInUrl, setSignInUrl] = useState(null)
  const userMii = useMemo(() => {
    return user && user.mii
      ? `https://cdn-mii.accounts.nintendo.com/2.0.0/mii_images/${user.mii.id}/${user.mii.etag}.png?type=face&width=512`
      : user
      ? genericMii
      : null
  }, [user])

  useEffect(() => {
    setSignInUrl(
      `https://accounts.nintendo.com/login?post_login_redirect_uri=${encodeURI(
        window.location.href
      )}`
    )
  }, [])

  return {
    loaded,
    error,
    user,
    signInUrl,
    userMii,
  }
}
