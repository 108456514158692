import React from "react"

import styles from "./desktop-slides.module.scss"

import useReduceMotion from "../../../../hooks/use-reduce-motion"

// Slides
import SlideOneRight from "./slide-one/slide-right"
import SlideOneLeft from "./slide-one/slide-left"
import SlideTwoRight from "./slide-two/slide-right"
import SlideTwoLeft from "./slide-two/slide-left"
import SlideThreeRight from "./slide-three/slide-right"
import SlideThreeLeft from "./slide-three/slide-left"

const animationDuration = 12000

export default function DesktopSlides({ left, right }) {
  const [rmState] = useReduceMotion()
  return (
    <>
      {left && (
        <div className={`image-group ${styles.Left}`}>
          <SlideOneLeft
            animationDuration={animationDuration}
            reduceMotion={rmState === 1 ? true : false}
          />
          {rmState !== 1 && (
            <>
              <SlideTwoLeft animationDuration={animationDuration} />
              <SlideThreeLeft animationDuration={animationDuration} />
            </>
          )}
        </div>
      )}
      {right && (
        <div className={`image-group ${styles.Right}`}>
          <SlideOneRight
            animationDuration={animationDuration}
            reduceMotion={rmState === 1 ? true : false}
          />
          {rmState !== 1 && (
            <>
              <SlideTwoRight animationDuration={animationDuration} />
              <SlideThreeRight animationDuration={animationDuration} />
            </>
          )}
        </div>
      )}
    </>
  )
}
