import React from "react"

import styles from "./mobile-slides.module.scss"

import useReduceMotion from "../../../../hooks/use-reduce-motion"

// Slides
import SlideOne from "./slide-one/slide-one"
import SlideTwo from "./slide-two/slide-two"
import SlideThree from "./slide-three/slide-three"

import useBreakpoints from "../../../../hooks/use-breakpoints"

export default function MobileSlides() {
  const currentBP = useBreakpoints()

  const animationDuration = currentBP === 2 ? 9000 : 10000

  const [rmState] = useReduceMotion()

  return (
    <div className={`image-group ${styles.Characters}`}>
      <SlideOne
        animationDuration={animationDuration}
        reduceMotion={rmState === 1 ? true : false}
      />
      {rmState !== 1 && (
        <>
          <SlideTwo animationDuration={animationDuration} />
          <SlideThree animationDuration={animationDuration} />
        </>
      )}
    </div>
  )
}
