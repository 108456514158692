import React from "react"
import PropTypes from "prop-types"

import Image from "../image/image"
import Button from "../button/button"
import Card from "../card/card"

import useInView from "../../hooks/use-in-view"
import useTracking from "../../hooks/use-tracking"

import { classnames } from "../../utils/helpers"

import baseStyles from "./tout.module.scss"
import useReduceMotion from "../../hooks/use-reduce-motion"

const Tout = function({
  images,
  description,
  cta,
  comingSoon,
  className,
  children,
  styles,
  trackPrefix,
  trackValue,
}) {
  const [rmState] = useReduceMotion()
  const [baseRef, isInView] = useInView()
  const { trackLink } = useTracking()

  return (
    <Card
      tag="div"
      className={classnames([
        baseStyles.Tout,
        className,
        styles.Tout,
        isInView && "vp-animate",
        isInView && baseStyles["vp-animate"],
        isInView && styles["vp-animate"],
        rmState === 1 && baseStyles["is-reduced-motion"],
        rmState === 1 && styles["is-reduced-motion"],
      ])}
      ref={baseRef}
    >
      <div className={classnames([baseStyles.Tout__img, styles.Tout__img])}>
        {images.map((image, index) => (
          <Image key={`tout-img-${index}`} {...image} alt="" />
        ))}
      </div>
      <div
        className={classnames([baseStyles.Tout__content, styles.Tout__content])}
      >
        {children}
        <p
          className={classnames([
            baseStyles.Tout__desc,
            styles.Tout__desc,
            "txt-big",
            "vp-slide",
            "vp-slide--down",
          ])}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {comingSoon && (
          <p
            className={classnames([
              baseStyles.Tout__desc,
              styles.Tout__desc,
              "vp-slide",
              "vp-slide--down",
            ])}
          >
            {comingSoon}
          </p>
        )}
        {cta && (
          <Button
            to={cta.url}
            color="yellow"
            arrow={true}
            className={classnames([
              baseStyles.Tout__cta,
              styles.Tout__cta,
              "vp-slide",
              "vp-slide--down",
              "vp-delay-1",
            ])}
            {...(cta.external && {
              target: "_blank",
              rel: "noopener nofollow noreferrer",
            })}
            onClick={event => {
              trackLink(
                {
                  eVars: {
                    39: `${trackPrefix ? trackPrefix + ": " : ""}${trackValue}`,
                  },
                  events: [53],
                },
                event
              )
            }}
          >
            {cta.label}
          </Button>
        )}
      </div>
    </Card>
  )
}

Tout.propTypes = {
  images: PropTypes.array.isRequired,
  description: PropTypes.string.isRequired,
  cta: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  styles: PropTypes.object,
  trackPrefix: PropTypes.string,
  trackValue: PropTypes.string,
}

Tout.defaultProps = {
  styles: {},
}

export default Tout
