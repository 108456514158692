import React, { useState, useRef, useCallback } from "react"
import PropTypes from "prop-types"

import { classnames } from "../../utils/helpers"

import useNclood from "../../hooks/use-nclood"
import useTracking from "../../hooks/use-tracking"

import Image from "../image/image"
import PlayButton from "../play-button/play-button"

import styles from "./video-inline.module.scss"

const VideoInline = ({
  className,
  id,
  imageProps,
  imagePropsSmall,
  label,
  name,
  trackPrefix,
  trackValue,
}) => {
  const { trackLink } = useTracking()
  const { loaded } = useNclood()
  const instance = useRef(null)
  const [playerRendered, setPlayerRendered] = useState(false)

  const renderVideo = useCallback(() => {
    if (loaded) {
      instance.current = new window.nclood.Video({
        target: document.getElementById(`inject-${name}`),
        videoId: id,
        name: name,
        autoplay: true,
      })

      setPlayerRendered(true)
    }
  }, [id, loaded, name])

  return (
    <div
      className={classnames([
        className,
        styles.VideoInline,
        playerRendered ? styles["VideoInline--rendered"] : "",
        "wrapper",
      ])}
    >
      <div className={styles.VideoInline__content}>
        <button
          className={styles.VideoInline__button}
          onClick={event => {
            renderVideo()
            trackLink(
              {
                eVars: {
                  39: `${trackPrefix ? trackPrefix + ": " : ""}${trackValue}`,
                },
                events: [37],
              },
              event
            )
          }}
        >
          <div className={classnames([imagePropsSmall ? "medium-up" : ""])}>
            <Image {...imageProps} className="img-full" alt={label} />
          </div>

          {imagePropsSmall && (
            <div className="small-only">
              <Image {...imagePropsSmall} className="img-full" alt={label} />
            </div>
          )}

          {loaded && (
            <PlayButton
              tag="span"
              centered={true}
              className={styles.VideoInline__icon}
            />
          )}
        </button>

        <div className={styles.VideoInline__inject} id={`inject-${name}`}></div>
      </div>
    </div>
  )
}

VideoInline.defaultProps = {
  name: "nclood-video-01",
  label: "",
}

VideoInline.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  imageProps: PropTypes.object,
  imagePropsSmall: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  trackPrefix: PropTypes.string,
  trackValue: PropTypes.string,
}

export default VideoInline
