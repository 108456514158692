import React from "react"

import styles from "./slide-left.module.scss"

import { useStaticQuery, graphql } from "gatsby"
import Image from "../../../../../components/image/image"

export default function SlideLeft({ animationDuration }) {
  const images = useStaticQuery(graphql`
    query {
      Brick: file(relativePath: { eq: "home/header/slide-three/brick.png" }) {
        childImageSharp {
          fixed(width: 156) {
            ...fluidImageWithDefault
          }
        }
      }
      BrickYellow: file(
        relativePath: { eq: "home/header/slide-three/brick-yellow.png" }
      ) {
        childImageSharp {
          fixed(width: 156) {
            ...fluidImageWithDefault
          }
        }
      }
    }
  `)

  return (
    <div
      style={{
        "--animation-duration": `${animationDuration}ms`,
      }}
    >
      <Image
        {...images.Brick.childImageSharp.fixed}
        className={`image-group__item ${styles.Brick} ${styles.BrickOne}`}
      />

      <Image
        {...images.BrickYellow.childImageSharp.fixed}
        className={`image-group__item ${styles.Brick} ${styles.BrickTwo}`}
      />

      <Image
        {...images.Brick.childImageSharp.fixed}
        className={`image-group__item ${styles.Brick} ${styles.BrickThree}`}
      />

      <Image
        {...images.Brick.childImageSharp.fixed}
        className={`image-group__item ${styles.Brick} ${styles.BrickFour}`}
      />

      <Image
        {...images.Brick.childImageSharp.fixed}
        className={`image-group__item ${styles.Brick} ${styles.BrickFive}`}
      />
    </div>
  )
}
