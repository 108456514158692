import React from "react"

import styles from "./slide-one.module.scss"

import { classnames } from "../../../../../utils/helpers"

import { useStaticQuery, graphql } from "gatsby"
import Image from "../../../../../components/image/image"

export default function SlideOne({ animationDuration, reduceMotion }) {
  const images = useStaticQuery(graphql`
    query {
      Mario: file(relativePath: { eq: "home/header/slide-one/mario.png" }) {
        childImageSharp {
          fixed(width: 244) {
            ...fluidImageWithDefault
          }
        }
      }
      Luigi: file(relativePath: { eq: "home/header/slide-one/luigi.png" }) {
        childImageSharp {
          fixed(width: 137) {
            ...fluidImageWithDefault
          }
        }
      }
      Bricks: file(
        relativePath: { eq: "home/header/slide-one/brick-row.png" }
      ) {
        childImageSharp {
          fixed(width: 312) {
            ...fluidImageWithDefault
          }
        }
      }
      Coin: file(relativePath: { eq: "home/header/coin.png" }) {
        childImageSharp {
          fixed(width: 60) {
            ...fluidImageWithDefault
          }
        }
      }
    }
  `)

  return (
    <div
      style={{
        "--animation-duration": `${animationDuration}ms`,
      }}
      className={classnames([reduceMotion && styles["images--reduced-motion"]])}
    >
      <Image
        {...images.Bricks.childImageSharp.fixed}
        className={`image-group__item ${styles.Bricks}`}
      />
      <Image
        {...images.Luigi.childImageSharp.fixed}
        className={`image-group__item ${styles.Luigi}`}
      />
      <Image
        {...images.Mario.childImageSharp.fixed}
        className={`image-group__item ${styles.Mario}`}
      />
      <Image
        {...images.Coin.childImageSharp.fixed}
        className={`image-group__item ${styles.Coin} ${styles.CoinOne}`}
      />
      <Image
        {...images.Coin.childImageSharp.fixed}
        className={`image-group__item ${styles.Coin} ${styles.CoinTwo}`}
      />
      <Image
        {...images.Coin.childImageSharp.fixed}
        className={`image-group__item ${styles.Coin} ${styles.CoinThree}`}
      />
      <Image
        {...images.Coin.childImageSharp.fixed}
        className={`image-group__item ${styles.Coin} ${styles.CoinFour}`}
      />
    </div>
  )
}
