import React, { useState, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Image from "../../components/image/image"
import Card from "../../components/card/card"
import Button from "../../components/button/button"

import useVp from "../../hooks/use-vp"
import useTracking from "../../hooks/use-tracking"
import useReduceMotion from "../../hooks/use-reduce-motion"
import useLocale from "../../hooks/use-locale"

import { classnames } from "../../utils/helpers"

import styles from "./home-shop.module.scss"

export default function HomeShop({ locale }) {
  const { langKey } = useLocale()
  let items = useMemo(() => {
    // something is off with gatsby
    // the CA url is getting used if
    // you navigate directly to the homepage
    // on the en-US locale. So I am
    // double, checking for the langKey
    if (langKey !== "ca") return locale.items

    return locale.items.map(item => {
      if (item.title === "Super Mario™ 3D World + Bowser's Fury")
        item.url =
          "https://www.nintendo.com/en_CA/games/detail/super-mario-3d-world-plus-bowsers-fury-switch/?"
      return item
    })
  }, [langKey, locale.items])

  const images = useStaticQuery(graphql`
    query {
      shopMario: file(relativePath: { eq: "home/shop/mario-background.png" }) {
        childImageSharp {
          fixed(width: 729) {
            ...fluidImageWithDefault
          }
        }
      }
      shopStar: file(relativePath: { eq: "home/shop/star.png" }) {
        childImageSharp {
          fixed(width: 230) {
            ...fluidImageWithDefault
          }
        }
      }
      shopItems: allFile(
        filter: {
          extension: { regex: "/(jpg)/" }
          relativeDirectory: { eq: "home/shop/items" }
        }
      ) {
        nodes {
          childImageSharp {
            fixed(width: 560) {
              ...fluidImageWithDefault
            }
          }
          relativePath
        }
      }
    }
  `)

  const decorationsRef = useVp(styles)
  const ctaRef = useVp()
  const { trackExitLink } = useTracking()
  const titleRef = useVp()
  const listRef = useVp(null, 0)

  // NOTE: this is here because of a weird bug with this component
  // where it doesn't repespect or get the upated reduce motion state
  const [rmState, setRmState] = useState(false)
  const rm = useReduceMotion()
  const getRm = rm[3]
  const baseRm = rm[0]

  useEffect(() => {
    setRmState(baseRm)
  }, [baseRm])

  useEffect(() => {
    setRmState(getRm())
  }, [getRm])
  // -----------

  return (
    <div
      className={classnames([
        styles.Shop,
        rmState === 1 && styles["is-reduced-motion"],
      ])}
    >
      <div ref={decorationsRef}>
        <div className={styles.Shop__mario}>
          <Image {...images.shopMario.childImageSharp.fixed} alt="" />
        </div>
        <div className={styles.Shop__star}>
          <Image {...images.shopStar.childImageSharp.fixed} alt="" />
        </div>
      </div>
      <div className="row row--horizontal-uncenter" ref={titleRef}>
        <h2
          className={classnames([
            styles.ShopTitle,
            "h1",
            "column",
            "column--7",
            "vp-slide",
            "vp-slide--down",
          ])}
        >
          <span>{locale.title}</span>
        </h2>
      </div>
      <ul className={classnames([styles.ShopGrid, "row"])} ref={listRef}>
        <li className="column column--6 column-large--4 vp-slide vp-slide--down">
          <div className={styles.ShopMainTile}>
            <a
              href={locale.main.url}
              target="_blank"
              rel="noopener nofollow noreferrer"
              onClick={event => {
                trackExitLink(
                  {
                    eVars: {
                      39: `home: featured: ${locale.main.trackValue}`,
                    },
                    events: [53],
                  },
                  event
                )
              }}
            >
              <Image
                src={locale.main.img}
                className={styles.ShopMainTile__img}
                alt=""
              />
              <Card
                className={styles.ShopMainTile__content}
                dots={false}
                hover={true}
              >
                <p className={classnames([styles.ShopMainTile__title, "h5"])}>
                  {locale.main.title}
                </p>
                <p>{locale.main.description}</p>
              </Card>
            </a>
          </div>
        </li>

        {items.map((item, index) => (
          <li
            key={`${index}-shop`}
            className={`column column--6 column-large--4 vp-slide vp-slide--down vp-delay-${index +
              1}`}
          >
            <Card
              className={styles.ShopTile}
              dots={false}
              {...(item.url
                ? {
                    href: item.url,
                    hover: true,
                    onClick: function(event) {
                      trackExitLink(
                        {
                          eVars: {
                            39: `home: featured: ${item.trackValue}`,
                          },
                          events: [53],
                        },
                        event
                      )
                    },
                  }
                : null)}
            >
              {item.imgInternal ? (
                <Image
                  wrapperClassName={styles.ShopTile__img}
                  className="img-full"
                  alt=""
                  {...images.shopItems.nodes.find(
                    image => image.relativePath === item.img
                  ).childImageSharp.fixed}
                />
              ) : (
                <Image
                  wrapperClassName={styles.ShopTile__img}
                  className="img-full"
                  alt=""
                  src={item.img}
                />
              )}
              <p className={classnames([styles.ShopTile__title, "h5"])}>
                {item.title}
              </p>
              <p className={styles.ShopTile__description}>{item.description}</p>
            </Card>
          </li>
        ))}
      </ul>
      <div ref={ctaRef} className="wrapper txt-center">
        <Button
          arrow={true}
          className="vp-slide vp-slide--down vp-delay-2"
          to={locale.more_cta.url}
          onClick={event => {
            trackExitLink(
              {
                eVars: {
                  39: `home: featured: more`,
                },
                events: [53],
              },
              event
            )
          }}
        >
          {locale.more_cta.label}
        </Button>
      </div>
      {locale.legal && (
        <p
          className={classnames([
            styles.Shop__legal,
            "wrapper",
            "txt-small",
            "txt-center",
          ])}
        >
          {locale.legal}
        </p>
      )}
    </div>
  )
}

HomeShop.propTypes = {
  locale: PropTypes.object,
}
