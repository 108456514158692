import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import styles from "./home-my-nintendo.module.scss"

import { classnames } from "../../../utils/helpers"

import useInView from "../../../hooks/use-in-view"
import useBreakPoints from "../../../hooks/use-breakpoints"

import Button from "../../../components/button/button"
import Image from "../../../components/image/image"
import Card from "../../../components/card/card"

export default function HomeMyNintendo({ locale }) {
  const [ref, inView] = useInView(0.1)
  const currentBP = useBreakPoints()

  const image = useStaticQuery(graphql`
    query {
      Mario: file(
        relativePath: { eq: "home/my-nintendo/mynintendo-mario.png" }
      ) {
        childImageSharp {
          fixed(width: 354) {
            ...fluidImageWithDefault
          }
        }
      }
      MarioLarge: file(
        relativePath: { eq: "home/my-nintendo/mynintendo-mario-large-up.png" }
      ) {
        childImageSharp {
          fixed(width: 546) {
            ...fluidImageWithDefault
          }
        }
      }
    }
  `)

  return (
    <div
      className={classnames([styles.Section, inView && "vp-animate"])}
      ref={ref}
    >
      <div className={classnames(["wrapper", styles.Card__Container])}>
        <Card dotColor="red" pattern="my-nintendo" className={styles.Card}>
          <div className={styles.Card__Content}>
            <h2
              className="h3 vp-slide vp-slide--down"
              dangerouslySetInnerHTML={{ __html: locale.title }}
            />
            <p
              className="vp-slide vp-slide--down vp-delay-2"
              dangerouslySetInnerHTML={{ __html: locale.copy }}
            />
            <div className="vp-slide vp-slide--down vp-delay-4">
              <Button
                to={locale.cta.url}
                arrow={true}
                small={true}
                color="red"
                hiddenText={locale.cta.hiddenText}
              >
                {locale.cta.label}
              </Button>
            </div>
          </div>
          {currentBP > 2 ? (
            <Image
              {...image.MarioLarge.childImageSharp.fixed}
              className={classnames([
                styles.Image,
                "vp-slide",
                "vp-slide--down",
              ])}
            />
          ) : (
            <Image
              {...image.Mario.childImageSharp.fixed}
              className={classnames([
                styles.Image,
                "vp-slide",
                "vp-slide--down",
              ])}
            />
          )}
        </Card>
      </div>
    </div>
  )
}

HomeMyNintendo.propTypes = {
  locale: PropTypes.object,
}
