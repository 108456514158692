import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import useLocale from "../../hooks/use-locale"

import Tout from "../tout/tout"

import { fluidImageWithDefault } from "../../utils/helpers"

import styles from "./timeline-tout.module.scss"

export default function TimelineTout({ trackPrefix }) {
  const { globalLocale } = useLocale()

  const images = useStaticQuery(graphql`
    query {
      timeline1: file(relativePath: { eq: "timeline-tout/character-1.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...fluidImageWithDefault
          }
        }
      }
      timeline2: file(relativePath: { eq: "timeline-tout/character-2.png" }) {
        childImageSharp {
          fixed(width: 356) {
            ...fluidImageWithDefault
          }
        }
      }
      timeline3: file(relativePath: { eq: "timeline-tout/character-3.png" }) {
        childImageSharp {
          fixed(width: 109) {
            ...fluidImageWithDefault
          }
        }
      }
    }
  `)

  return (
    <Tout
      styles={styles}
      images={[
        images.timeline1.childImageSharp.fixed,
        images.timeline2.childImageSharp.fixed,
        images.timeline3.childImageSharp.fixed,
      ]}
      description={globalLocale.timelineTout.description}
      cta={{ ...globalLocale.timelineTout.cta, url: "/history/" }}
      trackPrefix={trackPrefix}
      trackValue="timeline"
    />
  )
}

TimelineTout.propTypes = {
  trackPrefix: PropTypes.string,
}
