import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import useLocale from "../../hooks/use-locale"

import Tout from "../tout/tout"

import { fluidImageWithDefault } from "../../utils/helpers"

import styles from "./characters-tout.module.scss"

export default function CharactersTout({ trackPrefix }) {
  const { globalLocale } = useLocale()

  const image = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "characters-tout/characters.png" }) {
        childImageSharp {
          fixed(width: 343) {
            ...fluidImageWithDefault
          }
        }
      }
    }
  `)

  return (
    <Tout
      styles={styles}
      images={[image.file.childImageSharp.fixed]}
      description={globalLocale.charactersTout.description}
      cta={{ ...globalLocale.charactersTout.cta, url: "/characters/" }}
      trackPrefix={trackPrefix}
      trackValue="characters"
    />
  )
}

CharactersTout.propTypes = {
  trackPrefix: PropTypes.string,
}
