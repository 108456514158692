import React from "react"
import PropTypes from "prop-types"

import { classnames } from "../../utils/helpers"

import styles from "./play-button.module.scss"

export default function PlayButton({
  tag = "button",
  className,
  centered,
  ...props
}) {
  const Tag = tag

  return (
    <Tag
      className={classnames([
        styles.PlayButton,
        centered ? styles["PlayButton--centered"] : "",
        className,
      ])}
    >
      {/* hiding this text, as we're now passing SR text in as the image's alt text in the parent button. */}
      {/* <span className="visually-hidden">Play</span> */}
    </Tag>
  )
}

PlayButton.propTypes = {
  tag: PropTypes.string,
  className: PropTypes.string,
  centered: PropTypes.bool,
}
