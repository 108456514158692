import { useEffect, useRef } from "react"

import useInView from "./use-in-view"
import useReduceMotion from "./use-reduce-motion"

export default function useVp(styles, ...rest) {
  const [setRef, inView, targetRef] = useInView(...rest)
  const animated = useRef()
  const [rmState] = useReduceMotion()

  useEffect(() => {
    if (animated.current || !targetRef) return

    if (inView) {
      animated.current = true
      targetRef.classList.add("vp-animate")
      // console.log(rmState)
      if (styles && styles["vp-animate"])
        targetRef.classList.add(styles["vp-animate"])
    }
  }, [inView, rest, rmState, styles, targetRef])

  return setRef
}
