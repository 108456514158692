import React from "react"

import styles from "./slide-two.module.scss"

import { useStaticQuery, graphql } from "gatsby"
import Image from "../../../../../components/image/image"

export default function SlideTwo({ animationDuration }) {
  const images = useStaticQuery(graphql`
    query {
      Mush: file(relativePath: { eq: "home/header/slide-two/mush.png" }) {
        childImageSharp {
          fixed(width: 158) {
            ...fluidImageWithDefault
          }
        }
      }
      Mario: file(relativePath: { eq: "home/header/slide-two/mario.png" }) {
        childImageSharp {
          fixed(width: 320) {
            ...fluidImageWithDefault
          }
        }
      }
      Toad: file(relativePath: { eq: "home/header/slide-two/toad.png" }) {
        childImageSharp {
          fixed(width: 174) {
            ...fluidImageWithDefault
          }
        }
      }
      Star: file(relativePath: { eq: "home/header/slide-two/star.png" }) {
        childImageSharp {
          fixed(width: 316) {
            ...fluidImageWithDefault
          }
        }
      }
    }
  `)

  return (
    <div
      style={{
        "--animation-duration": `${animationDuration}ms`,
      }}
    >
      {/* Mush */}
      <Image
        {...images.Mush.childImageSharp.fixed}
        className={`image-group__item ${styles.Mush} ${styles.MushOne}`}
      />

      <Image
        {...images.Mush.childImageSharp.fixed}
        className={`image-group__item ${styles.Mush} ${styles.MushTwo}`}
      />

      <Image
        {...images.Mush.childImageSharp.fixed}
        className={`image-group__item ${styles.Mush} ${styles.MushThree}`}
      />

      <Image
        {...images.Star.childImageSharp.fixed}
        className={`image-group__item ${styles.Star}`}
      />

      <Image
        {...images.Toad.childImageSharp.fixed}
        className={`image-group__item ${styles.Toad}`}
      />

      <Image
        {...images.Mario.childImageSharp.fixed}
        className={`image-group__item ${styles.Mario}`}
      />
    </div>
  )
}
