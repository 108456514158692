import React from "react"

import styles from "./slide-three.module.scss"

import { useStaticQuery, graphql } from "gatsby"
import Image from "../../../../../components/image/image"

export default function SlideThree({ animationDuration }) {
  const images = useStaticQuery(graphql`
    query {
      Brick: file(relativePath: { eq: "home/header/slide-three/brick.png" }) {
        childImageSharp {
          fixed(width: 78) {
            ...fluidImageWithDefault
          }
        }
      }
      BrickYellow: file(
        relativePath: { eq: "home/header/slide-three/brick-yellow.png" }
      ) {
        childImageSharp {
          fixed(width: 78) {
            ...fluidImageWithDefault
          }
        }
      }
      Mario: file(relativePath: { eq: "home/header/slide-three/mario.png" }) {
        childImageSharp {
          fixed(width: 258) {
            ...fluidImageWithDefault
          }
        }
      }
      Peach: file(relativePath: { eq: "home/header/slide-three/peach.png" }) {
        childImageSharp {
          fixed(width: 127) {
            ...fluidImageWithDefault
          }
        }
      }
      Pipe: file(relativePath: { eq: "home/header/slide-three/pipe.png" }) {
        childImageSharp {
          fixed(width: 155) {
            ...fluidImageWithDefault
          }
        }
      }
    }
  `)

  return (
    <div
      style={{
        "--animation-duration": `${animationDuration}ms`,
      }}
    >
      {/* Bricks */}
      <Image
        {...images.Brick.childImageSharp.fixed}
        className={`image-group__item ${styles.Brick} ${styles.BrickOne}`}
      />
      <Image
        {...images.BrickYellow.childImageSharp.fixed}
        className={`image-group__item ${styles.Brick} ${styles.BrickYellow}`}
      />
      <Image
        {...images.Brick.childImageSharp.fixed}
        className={`image-group__item ${styles.Brick} ${styles.BrickTwo}`}
      />
      <Image
        {...images.Brick.childImageSharp.fixed}
        className={`image-group__item ${styles.Brick} ${styles.BrickThree}`}
      />

      <Image
        {...images.Pipe.childImageSharp.fixed}
        className={`image-group__item ${styles.Pipe}`}
      />

      <Image
        {...images.Peach.childImageSharp.fixed}
        className={`image-group__item ${styles.Peach}`}
      />

      <Image
        {...images.Mario.childImageSharp.fixed}
        className={`image-group__item ${styles.Mario}`}
      />
    </div>
  )
}
