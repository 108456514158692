import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import styles from "./home-header.module.scss"
import Button from "../../components/button/button"
import DesktopSlides from "./home-header-slides/desktop-slides/desktop-slides"
import MobileSlides from "./home-header-slides/mobile-slides/mobile-slides"

import useBreakpoints from "../../hooks/use-breakpoints"

import { classnames } from "../../utils/helpers"

export default function HomeHeader({ locale }) {
  const [animateIn, setAnimateIn] = useState(false)
  const currentBP = useBreakpoints()

  useEffect(() => {
    let timer = setTimeout(() => {
      setAnimateIn(true)
    }, 300)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <div className={styles.Header}>
      <div className="row">
        <div className="column column--8 column-large--6 column-xlarge--5">
          <div
            className={classnames([
              styles.HeaderCopy,
              animateIn && "vp-animate",
            ])}
          >
            <h2 className={styles.HeaderCopy__Title}>
              <span className="h4 vp-slide vp-slide--down-min txt-weight-black">
                {locale.titleTop}
              </span>
              <span
                className={`txt-extra-large txt-weight-black vp-slide vp-slide--down-min vp-delay-1 ${styles.HeaderCopy__TitleBottom}`}
              >
                {locale.titleBottom}
              </span>
            </h2>
            <p className="vp-slide vp-slide--down-min vp-delay-2 txt-big">
              {locale.copy}
            </p>
            <Button
              to={locale.cta.url}
              arrow={true}
              className="vp-slide vp-slide--down-min vp-delay-3"
            >
              {locale.cta.label}
            </Button>
          </div>
          {currentBP > 2 && <DesktopSlides left />}
        </div>
        <div className="column column--8 column-large--6 column-xlarge--7">
          {currentBP > 2 && <DesktopSlides right />}
          {currentBP <= 2 && <MobileSlides />}
        </div>
      </div>
    </div>
  )
}

HomeHeader.propTypes = {
  locale: PropTypes.object,
}
