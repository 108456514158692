import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import useLocale from "../../hooks/use-locale"

import Tout from "../tout/tout"

import { fluidImageWithDefault } from "../../utils/helpers"

import styles from "./news-tout.module.scss"

export default function NewsTout({ trackPrefix }) {
  const { globalLocale } = useLocale()

  const image = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "news-tout/placeholder.png" }) {
        childImageSharp {
          fixed(width: 366) {
            ...fluidImageWithDefault
          }
        }
      }
    }
  `)

  return (
    <Tout
      styles={styles}
      images={[image.file.childImageSharp.fixed]}
      description={globalLocale.newsTout.description}
      cta={{ ...globalLocale.newsTout.cta, url: "/news/" }}
      trackPrefix={trackPrefix}
      trackValue="read news"
    />
  )
}

NewsTout.propTypes = {
  trackPrefix: PropTypes.string,
}
