import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import useLocale from "../../hooks/use-locale"

import Tout from "../tout/tout"

import { fluidImageWithDefault } from "../../utils/helpers"

import styles from "./play-tout.module.scss"

export default function PlayTout({ trackPrefix }) {
  const { globalLocale } = useLocale()

  const image = useStaticQuery(graphql`
    query {
      play1: file(relativePath: { eq: "play-tout/characters.png" }) {
        childImageSharp {
          fixed(width: 468) {
            ...fluidImageWithDefault
          }
        }
      }
      play2: file(relativePath: { eq: "play-tout/logo.png" }) {
        childImageSharp {
          fixed(width: 468) {
            ...fluidImageWithDefault
          }
        }
      }
    }
  `)

  return (
    <Tout
      styles={styles}
      images={[
        image.play1.childImageSharp.fixed,
        image.play2.childImageSharp.fixed,
      ]}
      description={globalLocale.playTout.description}
      cta={{
        ...globalLocale.playTout.cta,
        url: "https://play.nintendo.com/themes/friends/mario/",
        external: true,
      }}
      trackPrefix={trackPrefix}
      trackValue="play nintendo"
    />
  )
}

PlayTout.propTypes = {
  trackPrefix: PropTypes.string,
}
