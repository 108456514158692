import React from "react"

import styles from "./slide-right.module.scss"

import { useStaticQuery, graphql } from "gatsby"
import Image from "../../../../../components/image/image"

export default function SlideRight({ animationDuration }) {
  const images = useStaticQuery(graphql`
    query {
      Mario: file(relativePath: { eq: "home/header/slide-three/mario.png" }) {
        childImageSharp {
          fixed(width: 515) {
            ...fluidImageWithDefault
          }
        }
      }
      Peach: file(relativePath: { eq: "home/header/slide-three/peach.png" }) {
        childImageSharp {
          fixed(width: 255) {
            ...fluidImageWithDefault
          }
        }
      }
      Pipe: file(relativePath: { eq: "home/header/slide-three/pipe.png" }) {
        childImageSharp {
          fixed(width: 309) {
            ...fluidImageWithDefault
          }
        }
      }
    }
  `)

  return (
    <div
      style={{
        "--animation-duration": `${animationDuration}ms`,
      }}
    >
      <Image
        {...images.Pipe.childImageSharp.fixed}
        className={`image-group__item ${styles.Pipe}`}
      />
      <Image
        {...images.Peach.childImageSharp.fixed}
        className={`image-group__item ${styles.Peach}`}
      />
      <Image
        {...images.Mario.childImageSharp.fixed}
        className={`image-group__item ${styles.Mario}`}
      />
    </div>
  )
}
