import React from "react"
import PropTypes from "prop-types"

import MediaGrid from "../../components/media-grid/media-grid"
import Button from "../../components/button/button"

import useInView from "../../hooks/use-in-view"
import useTracking from "../../hooks/use-tracking"

import { classnames } from "../../utils/helpers"

import styles from "./home-news.module.scss"

export default function HomeNews({ locale }) {
  const [ref, inView] = useInView(0.1)
  const { trackLink } = useTracking()

  return (
    <div
      className={classnames([inView && "vp-animate", styles.News])}
      ref={ref}
    >
      <div className="wrapper">
        <h2 className={classnames(["txt-center vp-slide vp-slide--down h1"])}>
          {locale.title}
        </h2>
      </div>
      <div className={styles.NewsRow}>
        <MediaGrid
          placeholders={3}
          itemRequestLimit={3}
          trackPrefix="home: news"
        />
      </div>
      <div className="txt-center">
        <Button
          to="/news/"
          arrow={true}
          className="vp-slide vp-slide--down vp-delay-2"
          onClick={event => {
            trackLink(
              {
                eVars: {
                  39: "home: news: more",
                },
                events: [53],
              },
              event
            )
          }}
        >
          {locale.cta.label}
        </Button>
      </div>
    </div>
  )
}

HomeNews.propTypes = {
  locale: PropTypes.object,
}
